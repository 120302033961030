import React from 'react'
import s from '../styles/404.module.scss'

const NotFound = () => {
  return (
    <div className={s.warpper}>
      <div className={s.sectionBox}></div>
    </div>
  )
}
export default NotFound
